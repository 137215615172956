
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ProductItemResponse } from "@/core/interfaces/ApiResponses";
import DissolutionReportTableAdd from "@/views/crafted/pages/dissolution-report/dissolution-report-table/DissolutionReportTableAdd.vue";
import { DissolutionService } from "@/core/services/Dissolution.service";

export default defineComponent({
  name: "new-children-dissolution-report",
  components: { DissolutionReportTableAdd },
  setup() {
    const route = useRoute();

    const isCreating = ref(true);
    const parentId = ref(-1);

    const reportNumber = ref("");
    const tableOutDissolution = ref();

    const product = ref<ProductItemResponse>({
      id: 0,
      ten_san_pham: "",
      ma_san_pham: "",
      so_hieu: "",
      mo_ta: "",
      ghi_chu: "",
      slug: "",
      id_nguoi_dung: 0,
      san_pham_meta: [],
      updated_at: "",
      created_at: "",
    });
    const repairingPlanName = ref("");
    const parentReportNumber = ref("");
    const repairingPlanId = ref(-1);

    const factoryDate = ref("");

    onMounted(async () => {
      setCurrentPageTitle("Trang quản lý biên bản giải thể");
      parentId.value = +route.params.parentId;
      const {
        data: {
          data: { so_bien_ban, ke_hoach_sua_chua, id_ke_hoach_sua_chua },
        },
      } = await DissolutionService.get(parentId.value);
      product.value = { ...ke_hoach_sua_chua.san_pham };
      repairingPlanName.value = ke_hoach_sua_chua.ten_ke_hoach;
      parentReportNumber.value = so_bien_ban;
      repairingPlanId.value = +id_ke_hoach_sua_chua;
    });

    const onSave = async () => {
      if (!reportNumber.value) {
        Swal.fire({
          text: "Bạn chưa nhập biên bản số",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      let dataDissolution = tableOutDissolution.value.data.filter(
        (item) => item.ten_vat_tu
      );
      if (dataDissolution.length === 0) {
        Swal.fire({
          text: "Bắt buộc phải nhập vật tư đối với biên bản bổ sung",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      let err = 0;
      dataDissolution.forEach((item) => {
        if (!item.quantity) err++;
      });
      if (err) {
        Swal.fire({
          text: "Bạn chưa nhập số lượng ngoài định mức",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }

      const res = await DissolutionService.createSubReport(
        reportNumber.value,
        moment(factoryDate.value).format("YYYY-MM-DD"),
        repairingPlanId.value,
        tableOutDissolution.value.data,
        parentId.value
      );
    };

    return {
      isCreating,
      reportNumber,
      factoryDate,
      parentId,
      repairingPlanName,
      parentReportNumber,
      product,
      tableOutDissolution,
      onSave,
    };
  },
});
